import React from "react";
import styled from "styled-components";
import { Text, SEO } from 'src/components/Common';
import { only, between } from 'styled-breakpoints';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Fade from 'react-reveal/Fade';

const Root = styled.main`
  height: 70vh;
  min-height: 700px;
  max-height: 900px;
  display: flex;

  ${between('mini', 'mobile')} {
    display: flex;
    flex-direction: column;
    height: auto;
    min-height: inherit;
    max-height: inherit;
  }
`;

const LeftSide = styled.div`
  flex-basis: 40vw;
  min-width: 615px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('/images/service_wave_vertical.svg');
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  padding: 0 20px;
  position: relative;

  ${between('mini', 'mobile')} {
    flex: 1;
    background-image: var(--linear);
    min-width: auto;
  }

  ${only('mobile')} {
    padding: 60px 20px;
  };

  ${only('mini')} {
    padding: 35px 0;
  };
`;

const RightSide = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 50px;

  ${between('mini', 'mobile')} {
    flex: 1;
    padding: 50px 20px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  opacity: 0.2;

  ${between('mini', 'mobile')} {
    right: 0;
  }

  svg { 
    width: ${props => `${props.width}`};

    ${only('mobile')} {
      width: 200px;
    };

    ${only('mini')} {
      width: 120px;
    };
  }
`;

const Service = ({ header, Icon, width = '33vw', seo, children }) => {
  const { i18n } = useI18next();
  return (
    <Root dir="ltr">
      <SEO
        title={seo?.title}
        description={seo?.description}
        breadcrumbsElementsSchema={seo?.breadcrumbsElementsSchema}
      />
      <LeftSide dir={i18n?.dir()}>
        <Fade duration={2000} top>
          <Text
            text={header}
            color="white"
            isBold
            whiteSpace="pre-line"
            variant="h2"
            component="h1"
          />
        </Fade>
        <IconWrapper width={width}>
          <Icon key="amit" />
        </IconWrapper>
      </LeftSide>
      <RightSide dir={i18n?.dir()}>
        <Fade duration={2000} bottom>
          {children}
        </Fade>
      </RightSide>
    </Root>
  );
}

export default Service;
