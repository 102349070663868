import React from "react";
import { graphql } from 'gatsby';
import Service from '../../components/Services/Service';
import BulbIcon from '/src/assets/servicesIcons/bulb_white.svg';
import { Text } from "../../components/Common";
import { useBreadcrumbsNesteadSchema } from '../../hooks/useBreadcrumbsNesteadSchema';
import { SERVICES_ORIGINAL_BREADCRUMBS } from '../../constants';

const UiUxPage = () => {
  const breadcrumbsElementsSchema = useBreadcrumbsNesteadSchema("UI/UX Design", SERVICES_ORIGINAL_BREADCRUMBS);

  return (
    <Service
      header="services page ui uix header"
      width="22vw"
      seo={{
        title: 'SEO title service ui ux',
        description: 'SEO description service ui ux',
        breadcrumbsElementsSchema
      }}
      Icon={BulbIcon}
    >
      <>
        <Text text="UI UX Design Description1" variant="body1" component="p" />
        <br />
        <Text text="UI UX Design Description2" variant="body1" component="p" />
        <br />
        <Text text="UI UX Design Description3" variant="body1" component="p" />
        <br />
        <Text text="UI UX Design Description4" variant="body1" component="p" />
        <br />
        <Text text="UI UX Design Description5" variant="body1" component="p" />
      </>
    </Service>
  );
}

export default UiUxPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
